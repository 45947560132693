.swiper-container {
	background-color: aqua;
	width: 500px;
}
.slider__img {
	width: 90%;
}
.swiper-wrapper {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.swiper-slide-next .slider__img {
}

#main {
	padding: 60px 0;
	margin-bottom: 5rem;
}
.swiper-slide {
	margin-bottom: 20px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	transition: all 0.3s ease;
}
.swiper-slide-active {
	transform: scale(1.15) !important;
	z-index: 2;
}
.swiper-slide-active {
}

/* swiper-pagination-bullet swiper-pagination-bullet-active */
.swiper-pagination-bullet {
	background-color: rgb(125, 151, 224) !important;
	width: 50px !important;
	border-radius: 0 !important;
	height: 3px !important;
}
