html {
	scroll-behavior: smooth;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
.slick-dots li button:before {
	color: #ecc94b !important;
}
.slick-dots {
	bottom: -73px !important;
}
